.LogoMaker__container{
    display: flex;
    flex-direction: row;
}

.LogoMaker__link{
    
}

.LogoMaker__Title {
    margin: 0 auto;
    margin-bottom: 15px;
    padding: 7px;
}

.LogoMaker__unstop{
    margin-top: 11px;
    font-size: 16px;
    color: white;
}

@media (max-width: 800px) {
    .LogoMaker__container {
        margin: 0 25%
    }   
}

@media (max-width: 600px) {
    .LogoMaker__container{
        flex-direction: column;
        align-items: center;
    }
    .LogoMaker__unstop{
        display: none;
        margin: -15px 0 20px 0;
        font-size: 16px;
        color: white;
    }
}
