@import url(https://fonts.googleapis.com/css2?family=Inconsolata:wght@300;400;500;700&display=swap);
/* Footer */

@font-face {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 600;
  src: local('Poppins SemiBold'),
    url(/static/media/Poppins-SemiBold.ac8d04b6.ttf) format('truetype');
}

@font-face {
  font-family: 'Poppins Light';
  font-style: normal;
  font-weight: 200;
  src: local('Poppins Light'),
    url(/static/media/Poppins-Light.7641a0f7.ttf) format('truetype');
}

.Footer {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  background: #262626;
  padding: 0px 0px 10px;
}
.Footer__Left {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  flex: 0.5 1;
  padding-left: 100px;
}
.Footer__Left p {
  font-size: 1rem !important;
  line-height: 1.5rem !important;
  color: #fafafa;
  margin: 10px 0 15px 0;
}
.Footer__LogoContainer {
  display: flex;
  text-decoration: none;
  color: #fafafa;
}
.Footer__Logo {
  font-family: "Poppins";
  font-weight: normal;
  font-size: 3.65rem;
  line-height: 3.5rem;
  margin: 0 32px 0 0;
}
.Footer__Tagline {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  font-size: 1.125rem;
  font-weight: 400;
  line-height: 1.25rem;
}
.Footer__SocialContainer {
  display: flex;
  align-items: center;
}
.Footer__SocialContainer svg {
  margin: 0 25px 0 0;
}
.Footer__Right {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-end;
  flex: 0.5 1;
  padding-right: 100px;
}

.Footer__Right a {
  font-size: 1rem !important;
  line-height: 1.5rem !important;
  color: #fafafa;
  text-decoration: underline;
  font-weight: bold;
}
.Footer__Right p {
  font-size: 1rem !important;
  line-height: 1.5rem !important;
  color: #fafafa;
  margin-top: 10px;
}

/* MEDIA QUERIES */
@media (max-width: 610px) {
  .Footer {
    flex-direction: column;
  }
  .Footer__Left {
    padding-left: 0px;
    justify-content: center;
    align-items: center;
  }
  .Footer__Right {
    padding-right: 0px;
    align-items: center;
    margin-top: 50px;
  }
  .Footer__SocialContainer {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  .Footer__SocialContainer a > svg {
    margin: 10px auto;
  }
}
@media (max-width: 350px) {
  .Footer__LogoContainer {
    flex-direction: column;
    align-items: center;
  }
  .Footer__Tagline {
    flex-direction: column;
    align-items: center;
  }
  .Footer__Logo {
    margin-right: 0px;
  }
}

.NavBar {
  width: 100vw;
  padding: 0px 100px;
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  background-color: #262626;
}
.NavBar__Title {
  width: 150px;
  display: block;
}
.NavBar__Right {
  display: flex;
  justify-content: flex-end;
  width: 60%;
  -webkit-user-select: none;
          user-select: none;
}
.NavBar__Network {
  font-size: 1.1rem;
  line-height: 1.5rem;
  color: #e5e5e5;
  box-sizing: border-box;
  padding: 8px 30px;
  height: -moz-fit-content;
  height: fit-content;
  width: auto;
  border: 2px solid #fbc51b;
  border-radius: 25px;
  font-weight: 700;
  font-family: "Poppins Light";
  display: flex;
  /* background-color: #; */
}
.NavBar__Address {
  font-size: 1.1rem;
  line-height: 1.5rem;
  color: #e5e5e5;
  box-sizing: border-box;
  padding: 8px 30px;
  height: -moz-fit-content;
  height: fit-content;
  width: -moz-fit-content;
  width: fit-content;
  border: 2px solid #fbc51b;
  border-radius: 25px;
  font-weight: 700;
  font-family: "Poppins Light";
  margin-left: 20px;
  display: flex;
}
.NavBar__UserIconContainer {
  width: 22px;
  height: 22px;
  position: relative;
  border: 1px solid #555555;
  border-radius: 50%;
  margin-left: 10px;
}
.NavBar__UserIcon {
  width: 20px;
  height: 20px;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 10;
  border: 1px solid #fafafa;
  border-radius: 50%;
}

.NavBar__Disconnect {
  /* display: absolute; */
  position: fixed;
  float: right;
  
}

/* MEDIA QUERIES*/
@media (max-width: 1050px) {
  .NavBar__Network {
    font-size: 1rem;
    line-height: 1rem;
    align-items: center;
  }
  .NavBar__Address {
    font-size: 1rem;
    line-height: 1rem;
    align-items: center;
  }
  .NavBar__UserIconContainer {
    width: 16px;
    height: 16px;
  }
  .NavBar__UserIcon {
    width: 14px;
    height: 14px;
  }
}

@media (max-width: 980px) {
  .NavBar__Right {
    width: 80%;
  }
}
@media (max-width: 910px) {
  .NavBar__Left {
    width: 100%;
  }
  .NavBar__Right {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
  }
  .NavBar__Network {
    margin-bottom: 5px;
  }
}
@media (max-width: 800px) {
  .NavBar {
    flex-direction: column;
  }
  .NavBar__Title {
    margin: 0 auto;
    margin-bottom: 15px;
  }
  .NavBar__Right {
    align-items: center;
  }
  .NavBar__Left {
    align-items: center;
    margin: 10px 20px;
  }
  .NavBar__Address {
    margin-left: 0;
  }
}
@media (max-width: 525px) {
  .NavBar {
    padding: 50px;
  }
  .NavBar__Network {
    font-size: 1rem;
  }
  .NavBar__Address {
    font-size: 1rem;
  }
  .NavBar__Right {
    width: 100%;
  }
  .NavBar__UserIconContainer {
    width: 22px;
    height: 22px;
    position: relative;
    border: 1px solid #555555;
    border-radius: 50%;
  }
  .NavBar__UserIcon {
    width: 20px;
    height: 20px;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 10;
    border: 1px solid #fafafa;
    border-radius: 50%;
  }
}

.LogoMaker__container{
    display: flex;
    flex-direction: row;
}

.LogoMaker__link{
    
}

.LogoMaker__Title {
    margin: 0 auto;
    margin-bottom: 15px;
    padding: 7px;
}

.LogoMaker__unstop{
    margin-top: 11px;
    font-size: 16px;
    color: white;
}

@media (max-width: 800px) {
    .LogoMaker__container {
        margin: 0 25%
    }   
}

@media (max-width: 600px) {
    .LogoMaker__container{
        flex-direction: column;
        align-items: center;
    }
    .LogoMaker__unstop{
        display: none;
        margin: -15px 0 20px 0;
        font-size: 16px;
        color: white;
    }
}

/* Complete container */
.Hero {
  width: 100%;
  height: 100%;
  color: white;
  font-family: "Poppins Light";
  background: #343232;
}
/* Complete container : END*/

/* Upper half container */
.Hero__View {
  /* padding: 90px 180px 80px 100px; */
  padding: 5% 10%;
  /* display: flex; */
}
.Hero__View h1 {
  font-size: 35px;
  align-content: center;
  margin-bottom: 30px;
  font-family: "Poppins Light";
}
.Hero__View h2 {
  font-weight: 500;
  font-size: 1.4rem !important;
  line-height: 2rem !important;
  margin: 2% 0;
  text-align: justify;
  font-family: "Poppins Light";
}
/* Upper half container : END */

.Hero__divider {
  margin: 10%;
}

.Hero__decription-container h1 {
  font-family: "Poppins Light";
  color: #fbc51b;
}

/* Links */
.Hero__LinkToWhitePaper {
  margin: 2% 0;
  font-family: "Inconsolata, monospace";
}
.Hero__LinkToWhitePaper a {
  font-weight: 500;
  font-size: 1.05rem;
  line-height: 1.5rem;
  color: #fbc51b;
}
/* Links : END */

/* Divider Line Breaker */
.Hero__line_breaker {
  width: 80%;
  margin: 1% auto;
  border: 1px solid #999999;
}
/* Divider Line Breaker : END */

.Hero__description_container {
  padding: 0% 5%;
}

.Hero__description_container h1 {
  padding: 0% 5%;
  color: #fbc51b;
}

/* Table styleing */
.Hero__description_container_table {
  padding: 0px 10% 0px 10px;
}

.Hero__row_header {
  font-weight: 550;
  font-size: 1.4rem;
  padding: 10% 15%;
}

/* Table styleing : END */

/* -- Lower half -- */

/* Lower half 'Duratoin' */
.Hero__Duration h2 {
  padding: 50px;
}
/* Lower half 'Duration' : END */

/* Lower half container */
.Hero__lower_half_container {
  /* padding: 20px 20px 20px 170px; */
  padding: 3% 10% 10% 10%;
}
/* Lower half container : END */

/* Dates */
.Hero__date_font {
  font-size: 1.2rem;
  font-weight: 400;
  background-color: #343232;
  color: white;
}
/* Dates : END */

/* Duration */
.Hero__duration_container {
  margin: 3% 15% 3% 0%;
}

.Hero__duration_container_table {
  padding: 2% 5% 5% 5%;
}
/* Duration : END */
.HeroParameterFeedNumberInputLarge {
  width: 50px;
  height: 25px;
  font-family: "Inconsolata";
  font-size: 18px;
  font-weight: 300;
  padding: 0;
  padding-left: 5px;
  padding-right: 5px;
  margin-right: 2px;
  margin-bottom: 3px;
  border: none;
  background-color: rgb(255, 255, 255);
  border-bottom: 2px solid #fbc51b;
  color: #000000;
  caret-color: #ffffff;
}
.HeroParameterFeedNumberInputLarge:focus,
.HeroParameterFeedNumberInputLarge:focus-visible,
.HeroParameterFeedNumberInputLarge:focus-within {
  outline-color: #fbc51b;
  outline-width: 1px;
}
/* Buttons */
.Hero__VoteInFavor {
  font-size: 1.4rem !important;
  line-height: 1.5rem !important;
  background: linear-gradient(90deg, #fbc51b 0%, #f2d883 100%);
  color: #555555;
  box-sizing: border-box;
  padding: 14px 40px;
  height: 50px;
  width: 90%;
  border: none;
  border-radius: 25px;
  font-weight: 600;
  font-family: "Poppins Light";
}
.Hero__VoteInFavor:hover {
  cursor: pointer;
}
.Hero__VoteInOpposition {
  font-size: 1.4rem !important;
  line-height: 1.5rem !important;
  background-color: #ec6f6f;
  color: #ffffff;
  box-sizing: border-box;
  padding: 5% 5%px;
  height: 3.5rem;
  width: 90%;
  border: 2px solid #f05b40;
  border-radius: 25px;
  font-weight: 600;
  font-family: "Poppins Light";
}
.Hero__VoteInOpposition:hover {
  cursor: pointer;
}

.Hero__buttons-container {
  border: 2px rgba(167, 163, 163, 0.705) solid;
  border-radius: 25px;
  background: #343232;
  padding: 5% 2%;
  margin: 5% 15% 0% 15%;
  font-size: 20px;
}
.Hero__your_vote {
  margin-bottom: 25px;
}
/* Buttons : END */

/* MEDIA QUERIES */
@media (max-width: 1300px) {
  .Hero__buttons-container {
    padding: 5% 2%;
    margin: 5% 5% 0% 5%;
  }
}

@media (max-width: 1100px) {
  .Hero__duration_container {
    margin: 3% 0% 3% 0%;
  }
  .Hero__duration_container_table {
    padding: 2% 0% 5% 0%;
  }
}

@media (max-width: 850px) {
  .Hero__View {
    padding: 4% 5%;
  }
  .Hero__CTAContainer {
    flex-direction: column;
  }

  .Hero__View h2 {
    padding: 0px 20px 0px 0px;
  }

  .Hero__row_header {
    font-weight: 550;
    font-size: 1.2rem;
    padding: 4% 5%;
  }

  .Hero__date_font {
    font-size: 1.1rem;
    font-weight: 400;
  }
}

@media (max-width: 770px) {
  .Hero__View {
    padding: 4% 5%;
  }
  .Hero__description_container {
    padding: 0%;
  }
  .Hero__duration_container {
    margin: 3% 5% 3% 5%;
  }
  .Hero__duration_container_table {
    padding: 2% 5% 5% 5%;
  }
  .Hero__duration_container_table h3 {
    font-size: 2rem;
  }
  .Hero__row_header {
    font-size: 1.5rem;
  }

  .Hero__row_header {
    font-weight: 550;
    font-size: 1.3rem;
    padding: 3% 0%;
  }

  .Hero__date_font {
    font-size: 1.2rem;
    font-weight: 400;
  }
}

@media (max-width: 525px) {
  .Hero__View {
    padding: 4% 5%;
  }
  .Hero__View h1 {
    margin-bottom: 15px;
  }
  .Hero__View h2 {
    font-size: 1.2rem !important;
    line-height: 2rem !important;
    margin: 5% 0;
    text-align: justify;
    font-family: "Poppins Light";
  }

  .Hero__duration_container_table {
    padding: 2% 0% 5% 0%;
  }

  .Hero__duration_container_table {
    padding: 2% 1% 5% 1%;
  }

  .Hero__lower_half_container {
    /* padding: 20px 20px 20px 170px; */
    padding: 3% 2% 10% 2%;
  }
}

@media (max-width: 420px) {
  .Hero__View {
    padding: 4% 5%;
  }
}

@media (max-width: 350px) {
  .Hero__View {
    padding: 3% 2%;
  }
  .Hero__View h1 {
    font-size: 25px;
  }
  .Hero__date_font {
    font-size: 1.1rem;
    font-weight: 400;
  }
  .Hero__row_header {
    font-weight: 550;
    font-size: 1.2rem;
  }
}

/* Attributes */
.bold {
  font-weight: 700 !important;
}
.underline {
  text-decoration: underline;
}
.special {
  font-size: 1.25rem !important;
  line-height: 1.5rem !important;
}
/* Attributes */

/* No Need */
.Hero__CTAContainer {
  margin-top: 50px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.Hero__CTAColumn {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  padding: 0 2%;
}
.Hero__CTAColumn h2 {
  margin-bottom: 10%;
}

.green {
  color: #fbc51b;
  background-color: #fbc51b;
  background: #fbc51b;
}
/* No Need : END */

/* 
-apple-system,
BlinkMacSystemFont,
"Segoe UI",
Roboto,
"Helvetica Neue",
Arial,
"Noto Sans",
sans-serif,
"Apple Color Emoji",
"Segoe UI Emoji",
"Segoe UI Symbol",
"Noto Color Emoji" 
*/

.ErrModal {
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  z-index: 10;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  overflow: auto;
  -ms-overflow-style: none;
  scrollbar-width: none;
  background-color: rgba(0, 0, 0, 0.4);
}
.ErrModal__Content {
  display: flex;
  flex-direction: column;
  align-items: center;
  scrollbar-width: 0;
  background: #fafafa;
  padding: 2% 4% 4% 4%;
  border-radius: 10px;
  width: 50%;
  height: -moz-fit-content;
  height: fit-content;
}
.ErrModal__Exit {
  width: 100%;
  display: flex;
  justify-content: flex-end;
  align-items: center;
}
.ErrModal__ExitIcon {
  width: 30px;
  height: 30px;
}
.ErrModal__ExitIcon:hover {
  cursor: pointer;
}
.ErrModal__Message {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.ErrModal__Message h1 {
  font-size: 1.25rem;
  margin-bottom: 5%;
}
.ErrModal__Message p {
  font-size: 1.1rem;
  font-weight: 300;
  text-align: center;
  line-height: 1.5rem;
}

.TxnLoader {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: fixed;
  z-index: 10;
  left: 0;
  top: 0;
  width: 100%;
  height: 100vh;
  overflow: auto;
  -ms-overflow-style: none;
  scrollbar-width: none;
  background-color: rgba(0, 0, 0, 0.4);
}
.TxnLoader h1 {
  font-size: 1.5rem;
  color: #fafafa;
}

.TxnLoader__Icon {
  margin-top: 20px;
  max-width: 15rem;
  width: 100%;
  height: auto;
  stroke-linecap: round;
}
.TxnLoader__Icon > circle {
  fill: none;
  stroke-width: 3.5;
  animation-name: preloader;
  animation-duration: 3s;
  animation-iteration-count: infinite;
  animation-timing-function: ease-in-out;
  transform-origin: 170px 170px;
  will-change: transform;
}
.TxnLoader__Icon > circle:nth-of-type(1) {
  animation-delay: -0.15s;
  stroke-dasharray: 550;
}
.TxnLoader__Icon > circle:nth-of-type(2) {
  animation-delay: -0.3s;
  stroke-dasharray: 500;
}
.TxnLoader__Icon > circle:nth-of-type(3) {
  animation-delay: -0.45s;
  stroke-dasharray: 450;
}
.TxnLoader__Icon > circle:nth-of-type(4) {
  animation-delay: -0.6s;
  stroke-dasharray: 300;
}

@keyframes preloader {
  50% {
    transform: rotate(360deg);
  }
}

.TxnModal {
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  z-index: 10;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  overflow: auto;
  -ms-overflow-style: none;
  scrollbar-width: none;
  background-color: rgba(0, 0, 0, 0.4);
}
.TxnModal__Content {
  display: flex;
  flex-direction: column;
  align-items: center;
  scrollbar-width: 0;
  background: #fafafa;
  padding: 2% 4% 4% 4%;
  border-radius: 10px;
  width: 50%;
  height: -moz-fit-content;
  height: fit-content;
}
.TxnModal__Exit {
  width: 100%;
  display: flex;
  justify-content: flex-end;
  align-items: center;
}
.TxnModal__ExitIcon {
  width: 30px;
  height: 30px;
}
.TxnModal__ExitIcon:hover {
  cursor: pointer;
}
.TxnModal__Message {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.TxnModal__Message h1 {
  font-size: 1.25rem;
  margin-bottom: 5%;
}
.TxnModal__Message p {
  font-size: 1.1rem;
  font-weight: 300;
  text-align: center;
  line-height: 1.5rem;
}
.TxnModal__Message a {
  margin-top: 2%;
  width: 350px;
  word-wrap: break-word;
  text-align: center;
  color: #555555;
  overflow: scroll;
}

.PleaseConnect__page{
  width: 100vw;
  height: 100vh;
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: center;
  background: #343232;
  color:white;
  font-family: "Inconsolata";
}
.PleaseConnect__Container {
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  padding: 5% 5% 10% 5%;
  font-family: "Inconsolata";
}
.PleaseConnect__Container h1 {
  margin-top: 10px;
  margin-bottom: 10px;
  font-size: 1.5rem;
  font-family: "Inconsolata";
}
.PleaseConnect__Swoosh {
  width: 50px;
  height: 50px;
  margin: 2%;
  font-family: "Inconsolata";
}
.PleaseConnect__Connect {
  margin: 0%;
  font-family: "Inconsolata"; 
}
.PleaseConnect__Connect button {
  font-size: 1rem !important;
  line-height: 1.5rem !important;
  background: linear-gradient(90deg, #fbc51b 0%, #f2d883 100%);
  color: #555555;
  box-sizing: border-box;
  padding: 14px 40px 18px 40px;
  height: 50px;
  width: 100%;
  border: none;
  border-radius: 25px;
  font-weight: 500;
  font-family: "Poppins Light";
}
.PleaseConnect__Connect button:hover {
  cursor: pointer;
}
.PleaseConnect__MetaMask p {
  font-size: 1em;
}
.PleaseConnect__MetaMaskLink {
  padding: 1% 1%;
  color: white;
}

.PleaseConnect__choose_wallet{
  /* border: 1px solid #383838; */
  display: flex;
  align-items: center;
  flex-direction: column;
  margin: 3% 0 10% 0;
}

.PleaseConnect__seperator{
  display: flex;
  flex-direction: row;
  align-items: center;
}

.PleaseConnect__line{
  height: 1px;
  width: 200px;
  margin: 0 5%;
  background-color: rgb(170, 170, 170);
}

.PleaseConnect__seperator_text{

}


@font-face {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 600;
  src: local('Poppins SemiBold'),
    url(/static/media/Poppins-SemiBold.ac8d04b6.ttf) format('truetype');
}

@font-face {
  font-family: 'Poppins Light';
  font-style: normal;
  font-weight: 200;
  src: local('Poppins Light'),
    url(/static/media/Poppins-Light.7641a0f7.ttf) format('truetype');
}

/* http://meyerweb.com/eric/tools/css/reset/ 
   v2.0 | 20110126
   License: none (public domain)
*/

html,
body,
div,
span,
applet,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
pre,
a,
abbr,
acronym,
address,
big,
cite,
code,
del,
dfn,
em,
img,
ins,
kbd,
q,
s,
samp,
small,
strike,
strong,
sub,
sup,
tt,
var,
b,
u,
i,
center,
dl,
dt,
dd,
ol,
ul,
li,
fieldset,
form,
label,
legend,
table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td,
article,
aside,
canvas,
details,
embed,
figure,
figcaption,
footer,
header,
hgroup,
menu,
nav,
output,
ruby,
section,
summary,
time,
mark,
audio,
video {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font-family: "Poppins Light";
  vertical-align: baseline;
}
/* HTML5 display-role reset for older browsers */
article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
menu,
nav,
section {
  display: block;
}
body {
  line-height: 1;
}
ol,
ul {
  list-style: none;
}
blockquote,
q {
  quotes: none;
}
blockquote:before,
blockquote:after,
q:before,
q:after {
  content: "";
  content: none;
}
table {
  border-collapse: collapse;
  border-spacing: 0;
}
/* Joshua Samaniego Updates */
html {
  width: 100vw;
  height: 100vh;
}
body {
  width: 100vw;
  height: 100vh;
  box-sizing: border-box;
  background-color: #fafafa;
}
.App {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

