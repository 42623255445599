.NavBar {
  width: 100vw;
  padding: 0px 100px;
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  background-color: #262626;
}
.NavBar__Title {
  width: 150px;
  display: block;
}
.NavBar__Right {
  display: flex;
  justify-content: flex-end;
  width: 60%;
  user-select: none;
}
.NavBar__Network {
  font-size: 1.1rem;
  line-height: 1.5rem;
  color: #e5e5e5;
  box-sizing: border-box;
  padding: 8px 30px;
  height: fit-content;
  width: auto;
  border: 2px solid #fbc51b;
  border-radius: 25px;
  font-weight: 700;
  font-family: "Poppins Light";
  display: flex;
  /* background-color: #; */
}
.NavBar__Address {
  font-size: 1.1rem;
  line-height: 1.5rem;
  color: #e5e5e5;
  box-sizing: border-box;
  padding: 8px 30px;
  height: fit-content;
  width: fit-content;
  border: 2px solid #fbc51b;
  border-radius: 25px;
  font-weight: 700;
  font-family: "Poppins Light";
  margin-left: 20px;
  display: flex;
}
.NavBar__UserIconContainer {
  width: 22px;
  height: 22px;
  position: relative;
  border: 1px solid #555555;
  border-radius: 50%;
  margin-left: 10px;
}
.NavBar__UserIcon {
  width: 20px;
  height: 20px;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 10;
  border: 1px solid #fafafa;
  border-radius: 50%;
}

.NavBar__Disconnect {
  /* display: absolute; */
  position: fixed;
  float: right;
  
}

/* MEDIA QUERIES*/
@media (max-width: 1050px) {
  .NavBar__Network {
    font-size: 1rem;
    line-height: 1rem;
    align-items: center;
  }
  .NavBar__Address {
    font-size: 1rem;
    line-height: 1rem;
    align-items: center;
  }
  .NavBar__UserIconContainer {
    width: 16px;
    height: 16px;
  }
  .NavBar__UserIcon {
    width: 14px;
    height: 14px;
  }
}

@media (max-width: 980px) {
  .NavBar__Right {
    width: 80%;
  }
}
@media (max-width: 910px) {
  .NavBar__Left {
    width: 100%;
  }
  .NavBar__Right {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
  }
  .NavBar__Network {
    margin-bottom: 5px;
  }
}
@media (max-width: 800px) {
  .NavBar {
    flex-direction: column;
  }
  .NavBar__Title {
    margin: 0 auto;
    margin-bottom: 15px;
  }
  .NavBar__Right {
    align-items: center;
  }
  .NavBar__Left {
    align-items: center;
    margin: 10px 20px;
  }
  .NavBar__Address {
    margin-left: 0;
  }
}
@media (max-width: 525px) {
  .NavBar {
    padding: 50px;
  }
  .NavBar__Network {
    font-size: 1rem;
  }
  .NavBar__Address {
    font-size: 1rem;
  }
  .NavBar__Right {
    width: 100%;
  }
  .NavBar__UserIconContainer {
    width: 22px;
    height: 22px;
    position: relative;
    border: 1px solid #555555;
    border-radius: 50%;
  }
  .NavBar__UserIcon {
    width: 20px;
    height: 20px;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 10;
    border: 1px solid #fafafa;
    border-radius: 50%;
  }
}
