.TxnModal {
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  z-index: 10;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  overflow: auto;
  -ms-overflow-style: none;
  scrollbar-width: none;
  background-color: rgba(0, 0, 0, 0.4);
}
.TxnModal__Content {
  display: flex;
  flex-direction: column;
  align-items: center;
  scrollbar-width: 0;
  background: #fafafa;
  padding: 2% 4% 4% 4%;
  border-radius: 10px;
  width: 50%;
  height: fit-content;
}
.TxnModal__Exit {
  width: 100%;
  display: flex;
  justify-content: flex-end;
  align-items: center;
}
.TxnModal__ExitIcon {
  width: 30px;
  height: 30px;
}
.TxnModal__ExitIcon:hover {
  cursor: pointer;
}
.TxnModal__Message {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.TxnModal__Message h1 {
  font-size: 1.25rem;
  margin-bottom: 5%;
}
.TxnModal__Message p {
  font-size: 1.1rem;
  font-weight: 300;
  text-align: center;
  line-height: 1.5rem;
}
.TxnModal__Message a {
  margin-top: 2%;
  width: 350px;
  word-wrap: break-word;
  text-align: center;
  color: #555555;
  overflow: scroll;
}
