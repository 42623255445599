/* Footer */

@font-face {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 600;
  src: local('Poppins SemiBold'),
    url('./assets/Poppins/Poppins-SemiBold.ttf') format('truetype');
}

@font-face {
  font-family: 'Poppins Light';
  font-style: normal;
  font-weight: 200;
  src: local('Poppins Light'),
    url('./assets/Poppins/Poppins-Light.ttf') format('truetype');
}

.Footer {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  background: #262626;
  padding: 0px 0px 10px;
}
.Footer__Left {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  flex: 0.5;
  padding-left: 100px;
}
.Footer__Left p {
  font-size: 1rem !important;
  line-height: 1.5rem !important;
  color: #fafafa;
  margin: 10px 0 15px 0;
}
.Footer__LogoContainer {
  display: flex;
  text-decoration: none;
  color: #fafafa;
}
.Footer__Logo {
  font-family: "Poppins";
  font-weight: normal;
  font-size: 3.65rem;
  line-height: 3.5rem;
  margin: 0 32px 0 0;
}
.Footer__Tagline {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  font-size: 1.125rem;
  font-weight: 400;
  line-height: 1.25rem;
}
.Footer__SocialContainer {
  display: flex;
  align-items: center;
}
.Footer__SocialContainer svg {
  margin: 0 25px 0 0;
}
.Footer__Right {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-end;
  flex: 0.5;
  padding-right: 100px;
}

.Footer__Right a {
  font-size: 1rem !important;
  line-height: 1.5rem !important;
  color: #fafafa;
  text-decoration: underline;
  font-weight: bold;
}
.Footer__Right p {
  font-size: 1rem !important;
  line-height: 1.5rem !important;
  color: #fafafa;
  margin-top: 10px;
}

/* MEDIA QUERIES */
@media (max-width: 610px) {
  .Footer {
    flex-direction: column;
  }
  .Footer__Left {
    padding-left: 0px;
    justify-content: center;
    align-items: center;
  }
  .Footer__Right {
    padding-right: 0px;
    align-items: center;
    margin-top: 50px;
  }
  .Footer__SocialContainer {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  .Footer__SocialContainer a > svg {
    margin: 10px auto;
  }
}
@media (max-width: 350px) {
  .Footer__LogoContainer {
    flex-direction: column;
    align-items: center;
  }
  .Footer__Tagline {
    flex-direction: column;
    align-items: center;
  }
  .Footer__Logo {
    margin-right: 0px;
  }
}
